import './Diagram.css';

function toHSL(str: string, opts: any) {
    var h, s, l;
    opts = opts || {};
    opts.hue = opts.hue || [0, 360];
    opts.sat = opts.sat || [75, 100];
    opts.lit = opts.lit || [40, 60];

    var range = function(hash: any, min: any, max: any) {
        var diff = max - min;
        var x = ((hash % diff) + diff) % diff;
        return x + min;
    }

    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    h = range(hash, opts.hue[0], opts.hue[1]);
    s = range(hash, opts.sat[0], opts.sat[1]);
    l = range(hash, opts.lit[0], opts.lit[1]);

    return `hsl(${h}, ${s}%, ${l}%)`;
}

function berth(x: number, y: number, rev: boolean, data: any) {
    if (rev) {
        return (
            <g style={{stroke: data && data.prev ? toHSL(data.descr, {}) as any : 'lightgray'}}>
                <line x1={x} y1={y + 10} x2={x + 50} y2={y + 10} />
                <line x1={x} y1={y + 9} x2={x + 20} y2={y + 20} />
                <line x1={x} y1={y + 11} x2={x + 20} y2={y} />
            </g>
        )
    }
    return (
        <g style={{stroke: data && data.prev ? toHSL(data.descr, {}) as any : 'lightgray'}}>
            <line x1={x} y1={y + 10} x2={x + 50} y2={y + 10} />
            <line x1={x + 50} y1={y + 9} x2={x + 30} y2={y + 20} />
            <line x1={x + 50} y1={y + 11} x2={x + 30} y2={y} />
        </g>
    )
}

function route(data: any, prev: string|Array<string>, path: string) {
    if (!(prev instanceof Array)) {
        prev = [prev];
    }

    return (<g>{data && prev.includes(data.prev) && <path d={path} className="active" style={{stroke: toHSL(data.descr, {}) as any}}></path>}</g>);
}

function Diagram({state} : {state:any}) {
    if (!state || !state.berths) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="610" height="420" className="diagram"></svg>
        );
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="42 32 526 350" className="diagram">
            <g>
                {berth(50, 50, false, state.berths['0107'])}
                {berth(50, 110, false, state.berths['0605'])}
                {berth(50, 170, false, state.berths['0289'])}
                {berth(50, 230, false, state.berths['0311'])}

                <text x="520" y="75" className="siding" style={{fill: (state.berths['0520'] || state.berths['0632']) && (state.berths['0520'] || state.berths['0632']).prev ? toHSL((state.berths['0520'] || state.berths['0632']).descr, {}) as any : 'lightgray'}}>{state.siding.num_trains}</text>
                {berth(510, 170, true, state.berths['0106'])}
                {berth(510, 230, true, state.berths['0288'])}
                {berth(510, 290, true, state.berths['0310'])}
                {berth(510, 350, true, state.berths['0492'])}
                
                <path d="M 110,60 500,60" className="base"></path>
                <path d="M 200,60 320,120" className="base"></path>
                <path d="M 350,120 470,60" className="base"></path>

                <path d="M 110,120 500,120" className="base"></path>
                <path d="M 170,120 290,180" className="base"></path>

                <path d="M 110,180 500,180" className="base"></path>
                <path d="M 140,240 260,180" className="base"></path>

                <path d="M 110,240 500,240" className="base"></path>

                <path d="M 110,300 500,300" className="base"></path>
                <path d="M 260,360 380,300" className="base"></path>

                <path d="M 110,360 500,360" className="base"></path>

                {route(state.berths['1051'], '0107', 'M 110,60 500,60')}
                {route(state.berths['1051'], '0605', 'M 110,120 350,120 470,60 500,60')}
                {route(state.berths['0109'], '0107', 'M 110,60 200,60 320,120 500,120')}
                {route(state.berths['0109'], '0605', 'M 110,120 500,120')}
                {route(state.berths['0291'], '0605', 'M 110,120 170,120 290,180 500,180')}
                {route(state.berths['0291'], '0289', 'M 110,180 500,180')}
                {route(state.berths['0291'], '0311', 'M 110,240 140,240 260,180 500,180')}
                {route(state.berths['0313'], '0311', 'M 110,240 500,240')}

                {route(state.berths['0610'], ['0520', '0632'], 'M 500,60 110,60')}
                {route(state.berths['0512'], ['0520', '0632'], 'M 500,60 470,60 350,120 110,120')}
                {route(state.berths['0512'], '0106', 'M 500,120 110,120')}
                {route(state.berths['0104'], '0106', 'M 500,180 110,180')}
                {route(state.berths['0286'], '0106', 'M 500,180 260,180 140,240 110,240')}
                {route(state.berths['0286'], '0288', 'M 500,240 110,240')}
                {route(state.berths['0308'], '0310', 'M 500,300 110,300')}
                {route(state.berths['0490'], '0310', 'M 500,300 380,300 260,360 110,360')}
                {route(state.berths['0490'], '0492', 'M 500,360 110,360')}
            </g>
        </svg>            
    )
}

export default Diagram;