import React, {useEffect} from 'react';
import './App.css';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Diagram from './Diagram';
import Schedule from './Schedule';

function App() {
  const {
    lastJsonMessage,
    readyState,
  } = useWebSocket((document.location.protocol == 'https:' ? 'wss://' : 'ws://') + document.location.host + '/api', {
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  /*
  const interactiveCanvas = (window as any).interactiveCanvas;
  interactiveCanvas.ready({
    onUpdate: (data: any) => {
      const intent = data[0].google.intent;
      // intent name, params
    },
  });
  */
  // interactiveCanvas.getHeaderHeightPx()

  // <header className="App-header">Trains</header>
  return (
    <div className="App">
      <main>
        <Schedule state={lastJsonMessage}></Schedule>
        <Diagram state={lastJsonMessage}></Diagram>
      </main>
    </div>
  );
}

export default App;
