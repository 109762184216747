import './Schedule.css';
import { useState, useEffect } from 'react';

function formatTime(timestamp: number, format: number) {
    if (!timestamp) {
        return '';
    }

    const diffMin = (timestamp - Date.now()) / 60000;
    if (diffMin <= 99 && format != 3) {
        if (diffMin > 0.5) {
            return (format >= 1 ? ' in ' : '') + Math.round(diffMin) + 'min';
        }
        if (diffMin >= -2) {
            return format == 1 ? '' : format == 2 ? ' soon' : 'now';
        }
        if (diffMin >= -99 && format >= 1) {
            return (format == 1 ? ' (' : ' ') + Math.round(-diffMin) + 'min ago' + (format == 1 ? ')' : '');
        }
    }

    const date = new Date(timestamp);
    const hours = '0' + date.getHours();
    const minutes = '0' + date.getMinutes();
    return (format == 1 ? ' (at ' : format == 2 ? ' at ' : '') + hours.substr(-2) + ':' + minutes.substr(-2) + (format == 1 ? ')' : '') ;
}

function adjustedTimestamp(train: any) {
    let ts = train.pass_timestamp;
    if (train.variation_status == 'EARLY') {
        ts -= train.variation_minutes * 60000;
    }
    if (train.variation_status == 'LATE') {
        ts += train.variation_minutes * 60000;
    }
    return ts;
}

function toHSL(str: string, opts: any) {
    var h, s, l;
    opts = opts || {};
    opts.hue = opts.hue || [0, 360];
    opts.sat = opts.sat || [75, 100];
    opts.lit = opts.lit || [40, 60];

    var range = function(hash: any, min: any, max: any) {
        var diff = max - min;
        var x = ((hash % diff) + diff) % diff;
        return x + min;
    }

    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    h = range(hash, opts.hue[0], opts.hue[1]);
    s = range(hash, opts.sat[0], opts.sat[1]);
    l = range(hash, opts.lit[0], opts.lit[1]);

    return `hsl(${h}, ${s}%, ${l}%)`;
}

function Locations(locations: Array<any>) {
    const out = [];
    for (let i = 0; i < locations.length; i++) {
        const location = locations[i];
        const target = location.tiploc == 'CMDNSTH' || location.tiploc == 'CMDNCSD';
        out.push(<div key={i} className={target ? 'location target' : location.is_most_recent ? 'location latest' : location.off_schedule ? 'location off' : location.has_passed ? 'location passed' : 'location'} title={formatTime(location.pass_timestamp, 3) + ' ' + location.desc + (location.off_schedule ? ' (off schedule)' : '')}></div>);
    }
    return (<div className="locations">{out}</div>);
}

function Schedule({state} : {state:any}) {
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
      }, []);

    if (!state || !state.trains) {
        return (
            <div></div>
        );
    }

    const trains = Object.values(state.trains) as any;
    trains.sort((a : any, b : any) => (adjustedTimestamp(a) > adjustedTimestamp(b)) ? 1 : -1);

    const current = [];
    const future = [];
    for (const train of trains) {
        let status;
        if (train.last_location_timestamp) {
            status = (<span>{train.last_location} {!!train.variation_minutes && train.variation_minutes + 'min'} {train.variation_status}{formatTime(train.last_location_timestamp, 1)}</span>);
        } else {
            status = (<span>departs {train.last_location}{formatTime(train.departure_timestamp, 2)}</span>);
        }

        // {train.locations && train.locations.length && Locations(train.locations)}
        const render = (
            <div key={train.train_id} className={train.in_view ? 'schedule-train in-view' : 'schedule-train'} style={{backgroundColor: train.in_view && toHSL(train.train_id.substring(2, 6), {}) as any, padding: train.in_view ? '8px' : '0'}}>
                {!train.in_view && <div className="schedule-time">{formatTime(adjustedTimestamp(train), 0)}</div>}
                <div className="schedule-right">
                    {train.train_type && train.locations && train.locations.length && <div className="schedule-sch text"><b>{train.train_type}</b>: {train.locations[0].desc} - {train.locations[train.locations.length - 1].desc}</div>}
                    <div className="schedule-status text">{status}</div>
                    {train.in_view && !train.in_area && <div>not in area</div>}
                </div>
            </div>
        );

        if (train.in_view) {
            current.push(render);
        } else {
            future.push(render);
        }        
    }

    return (
        <div className="schedule">{current}{future}</div>
    )
}

export default Schedule;